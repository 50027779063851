/* src/assets/fonts/fonts.css */

/* Roboto Thin */
@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }
  
  /* Roboto Thin Italic */
  @font-face {
    font-family: 'Roboto';
    src: url('./Roboto-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
  }
  
  /* Roboto Light */
  @font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  
  /* Roboto Light Italic */
  @font-face {
    font-family: 'Roboto';
    src: url('./Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }
  
  /* Roboto Regular */
  @font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  
  /* Roboto Regular Italic */
  @font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }
  
  /* Roboto Medium */
  @font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  /* Roboto Medium Italic */
  @font-face {
    font-family: 'Roboto';
    src: url('./Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }
  
  /* Roboto Bold */
  @font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  
  /* Roboto Bold Italic */
  @font-face {
    font-family: 'Roboto';
    src: url('./Roboto-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }
  
  /* Roboto Black */
  @font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  
  /* Roboto Black Italic */
  @font-face {
    font-family: 'Roboto';
    src: url('./Roboto-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }

/* Roboto Thin */
.roboto-thin {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  /* Roboto Light */
  .roboto-light {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  /* Roboto Regular */
  .roboto-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  /* Roboto Medium */
  .roboto-medium {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  /* Roboto Bold */
  .roboto-bold {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  /* Roboto Black */
  .roboto-black {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  /* Roboto Thin Italic */
  .roboto-thin-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  /* Roboto Light Italic */
  .roboto-light-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  /* Roboto Regular Italic */
  .roboto-regular-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  /* Roboto Medium Italic */
  .roboto-medium-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  /* Roboto Bold Italic */
  .roboto-bold-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  /* Roboto Black Italic */
  .roboto-black-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: italic;
  }  