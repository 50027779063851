/* styles.css */

@import './assets/fonts/fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Optional: Apply Roboto globally */
@layer base {
  body {
    @apply font-sans;
  }
}

@layer utilities {
  .morph-background {
    @apply relative w-full h-full bg-cover bg-center animate-morph;
    background-blend-mode: multiply;
    box-shadow: inset 0 0 0 18px rgba(255, 255, 255, 0.3);
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    animation: morph 8s ease-in-out infinite;
  }
  
  .brush-wrap {
    position: relative;
    display: inline-block;
    padding: 3rem;
  }
  
  .brush-wrap.brush-wrap--indefinite:before {
    clip-path: url(#clip-indefinite);
  }
  
  .brush-wrap:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: black;
    z-index: -1;
    clip-path: url(#clip); /* applying clip animation */
  }
  
  .brush-wrap p {
    font-size: 2rem;
    text-transform: uppercase;
    margin: 0;
    color: white;
    font-style: italic;
    filter: drop-shadow(0px 0px 2px black);
  }
}